import React from "react"
import MainLayout from "../components/MainLayout"
import styled from 'styled-components'
import SEO from "../components/SEO"

const Container = styled.div`
  background-color: white;
  padding: 0 4rem;
  @media (max-width: 900px) {
    padding: 2rem 1rem;
  }
`

export default ({location: {pathname}}) => (
  <MainLayout>
    <SEO title="Impressum" pathname={pathname}/>
    <Container>
    <h1>Impressum</h1>
    <h2>
      Information gemäß § 5 E-Commerce-Gesetz und Offenlegung gemäß § 25
      Mediengesetz:
    </h2>
    <p>
      Diensteanbieter und Medieninhaber:
      <br />
      Dr. Maximilian Rom, F.E.B.U.
    </p>
    <p>
      Anschrift:
      <br />
      Breitenfurter Straße 376/1/E/3
      <br />
      1230 Wien
    </p>
    <p>
      Tel.: 01/8907050
      <br />
      E-Mail: ordination@urologe-rom.at
      <br />
      Homepage: www.urologe-rom.at
    </p>
    <p>
      Berufsbezeichnung /Unternehmensgegenstand: Facharzt für Urologie
      (verliehen in Österreich)
      <br />
      Mitglied der Ärztekammer für Wien
      <br />
      Tätigkeit unterliegt dem Ärztegesetz 1998
      <br />
      (siehe http://www.ris.bka.gv.at/bundesrecht)
    </p>
    <h2>Haftungsausschluss, Urheber- und Kennzeichenrechte, Datenschutz</h2>
    <p>
      Dr. Maximilian Rom (der „Autor“) übernimmt keine Haftung für die
      Aktualität, Korrektheit, Vollständigkeit oder Qualität der
      bereitgestellten Informationen. Alle Angebote sind freibleibend und
      unverbindlich. Die dargestellten Inhalte haben lediglich informativen
      Charakter. Sie können und dürfen nicht zur Erstellung von Diagnosen oder
      für die Auswahl bzw. Anwendung von Behandlungsmethoden verwendet werden.
      Diese Informationen ersetzen nicht die professionelle Beratung oder
      Behandlung durch einen Arzt.
    </p>
    <p>
      Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
      gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
      löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
      Die Nutzung der auf diesem Internetangebot veröffentlichten Kontaktdaten
      wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch
      Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen
      ist nicht gestattet. Rechtliche Schritte gegen die Versender von so
      genannten Spam-Mails bei Verstößen gegen dieses Verbot sind ausdrücklich
      vorbehalten.
    </p>
    <p>
      Im Hinblick auf allfällige Verweise (Links) auf fremde Internetangebote,
      erklärt der Autor ausdrücklich, dass im Zeitpunkt der Linksetzung keine
      illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
      aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der
      gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb
      distanziert er sich hiermit ausdrücklich von den Inhalten aller
      gelinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden.
      Für den Inhalt verlinkter Seiten sind ausschließlich deren Betreiber
      verantwortlich.
    </p>
    <p>
      Der Autor ist bestrebt, im Rahmen dieses Internetangebots allenfalls
      bestehende Urheberrechte zu beachten. Die Urheber- und Kennzeichenrechte
      von vom Autor selbst erstellten Objekten (Texten, Bildern, Grafiken,
      Tondokumenten oder Videosequenzen) verbleiben allein beim Autor. Eine
      Vervielfältigung oder Verwendung solcher Objekte durch Dritte in
      elektronischen oder sonstigen Medien ohne ausdrückliche Zustimmung des
      Autors ist nicht gestattet.
    </p>
    <p>
      Die allfällige Preisgabe persönlicher oder geschäftlicher Daten eines
      Nutzers dieses Internetangebots an den Diensteanbieter erfolgt auf
      ausdrücklich freiwilliger Basis.{" "}
    </p>
    <p>
      Dieser Haftungsausschluss ist Teil des Internetangebotes. Sofern Teile
      oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
      nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen
      Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
    </p>
    </Container>
  </MainLayout>
)
